@font-face {
  font-family: "Nunito Sans";
  src: url("./fonts/NunitoSans-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Nunito Sans";
  src: url("./fonts/NunitoSans-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Nunito Sans";
  src: url("./fonts/NunitoSans-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Nunito Sans";
  src: url("./fonts/NunitoSans-Bold.ttf");
  font-weight: 700;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Snackbar-success {
  background: #FBFFFA !important;
  border: 1px solid #89C57A !important;
  border-radius: 25px !important;
  color: #212121 !important;
  padding: 8px 16px !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-family: "Nunito Sans", sans-serif !important;
}

.Snackbar-error {
  background: #FFF5F5 !important;
  border: 1px solid #FF0000 !important;
  border-radius: 25px !important;
  color: #212121 !important;
  padding: 8px 16px !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-family: "Nunito Sans", sans-serif !important;
}

.Snackbar-success svg, .Snackbar-error svg {
  margin-right: 8px !important;
}

.Snackbar-error svg {
  fill:  #FF0000
}
